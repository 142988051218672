<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Lomakkeet</span>
    </div>

    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          v-if="isAuthorized('form', 'create')"
          color="info"
          class="mb-2 mr-1 ml-auto"
          @click="$router.push('/form/create').catch(() => {})"
        >
          Uusi lomake
        </v-btn>
      </v-card-title>

      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Nimi"
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <!-- TABLE -->
      <v-data-table
        :headers="headers"
        :items="forms"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- Link -->
        <template #[`item.link`]="{ item }">
          <span
            >Kopioi linkki
            <v-icon class="ml-1" small @click="copyLink(item)">mdi-content-copy</v-icon></span
          >
        </template>

        <!-- Active -->
        <template #[`item.active`]="{ item }">
          <span>{{ formatBoolean(item.active) }}</span>
          <v-switch
            style="padding: 0px !important; margin: 0px !important"
            v-model="item.active"
            small
            hide-details
            @change="updateFormActiveState(item)"
          ></v-switch>
          <small v-if="!item.active">Mikään lomake ei ole täytettävissä</small>
        </template>

        <!-- Actions -->

        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('form', 'edit')"
              title="Muokkaa"
              small
              @click="$router.push(`/form/edit/${item._id}`).catch(() => {})"
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="isAuthorized('form', 'create')"
              title="Kopio lomakepohja"
              small
              @click="createFormJson(item)"
              >mdi-content-copy</v-icon
            >
            <v-icon
              v-if="isAuthorized('form', 'delete')"
              title="Poista"
              small
              @click="deleteItem(item._id)"
              >mdi-delete</v-icon
            >
          </div>
        </template>

        <template #no-data>
          <h2>Ei lomakkeita</h2>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapMutations, mapState } from "vuex";
import globalValues from "@/configs/globalValues";

export default {
  title: "Lomakkeet",

  mixins: [mixins],

  data: () => ({
    loading: false,
    attachApartmentDialog: false,
    options: {},
    search: "",
    headers: [
      { text: "Nimi", value: "name" },
      { text: "Linkki", value: "link" },
      { text: "Vastaanottajat", value: "recipients" },
      { text: "Aktiivinen", value: "active" },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("form", ["totalLength", "forms"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
  },

  watch: {
    options: {
      deep: true,
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("form", ["getForms", "deleteForm", "updateFormActiveState"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("form", ["setAttachment"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      const search = this.search.trim().toLowerCase();

      // set searching to true or false
      this.searching = search;

      let url = `/api/v1/form/get-all?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

      await this.getForms(url);

      this.loading = false;
    },

    editItem(item) {
      this.$router.push("/form/edit/" + item._id);
    },

    deleteItem(id) {
      if (
        confirm(
          "Oletko varma, että haluat poistaa lomakkeen? Jos lomake on jossain käytössä, lomaketta ei pääse enää täyttämään. Myöskään lomakkeen yhteydenottoja ei enää näe."
        )
      ) {
        this.deleteForm(id);
      }
    },

    linkToForm(item) {
      const host = window.location.host;
      if (host.startsWith("localhost")) {
        return `http://${host}/lomakkeet/${item._id}`;
      } else {
        return `https://${host}/lomakkeet/${item._id}`;
      }
    },

    createFormJson(item) {
      let rows = item.rows;
      let rules = item.rules;
      const styles = item.styles;

      rules = rules.map((el) => {
        delete el._id;
        return el;
      });

      rows = rows.map((row) => {
        delete row._id;
        row.cols = row.cols.map((col) => {
          delete col._id;
          return col;
        });
        return row;
      });

      const jsonString = JSON.stringify({ rows, styles, rules });

      navigator.clipboard
        .writeText(jsonString)
        .then(() => {
          this.showPopup(
            "Lomakkeen koodi kopioitu leikepöydälle. Voit liittää sen uudelle lomakkeelle lomakkeen luonnissa",
            "success"
          );
        })
        .catch(() => {
          this.showPopup("Virhe kopioinnissa leikepöydälle", "error");
        });
    },

    copyLink(item) {
      const link = this.linkToForm(item);

      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.showPopup("Linkki kopioitu leikepöydälle", "success");
        })
        .catch(() => {
          this.showPopup("Ongelma linkin kopioimisessa", "error");
        });
    },
  },
};
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
